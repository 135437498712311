import { Container, Grid, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
}));

export const Footer = () => {
	//Accordian
	const classes = useStyles();
	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	//Accordian

	return (
		<div className={classes.root}>
			<Box
				className="footer"
				component="section"
				style={{ backgroundColor: "#fff" }}
			>
				<Container className="bd">
					<Grid container>
						<Grid item lg={6} sm={5} xs={12}>
							<Typography className="footer_one_text">
								Terms and Conditions | Privacy Policy
							</Typography>
						</Grid>
						<Grid item lg={6} sm={7} xs={12}>
							<Typography className="footer_two_text">
								Copyright © 2023 - {new Date().getFullYear()}
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</div>
	);
};
